import React from "react"
import RecentProjects from "../components/RecentProjects"
import Layout from "../components/Layout"

const seoProps = {
  title: "Projects",
  description:
    "Stevenson Roofing have been contracted for hundreds of roofing jobs, this page highlights some of our recent work",
  pathname: "/projects",
}

const ProjectsPage = () => (
  <Layout seoProps={seoProps}>
    <RecentProjects />
  </Layout>
)

export default ProjectsPage
